import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faFacebook,
  faDrupal,
} from '@fortawesome/free-brands-svg-icons'

const Footer = () => (
  <footer className="footer">
    <p>
      <a href="https://www.drupal.org/crowdcg" target="_blank">
        <FontAwesomeIcon icon="faDrupal" />
        <span className="sr-only">Drupal</span>
      </a>
      <a href="https://www.twitter.com/CrowdCG" target="_blank">
        <FontAwesomeIcon icon="faTwitter" />
        <span className="sr-only">Twitter</span>
      </a>
      <a href="https://www.facebook.com/CrowdCG" target="_blank">
        <FontAwesomeIcon icon="faFacebook" />
        <span className="sr-only">Facebook</span>
      </a>
    </p>
    <p>&copy; 2022 Crowd Communications Group, LLC</p>
  </footer>
)

export default Footer
