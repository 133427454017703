import React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/crowdcg-logo.svg'

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="logo">
      <Link to="/">
        <img src={Logo} alt={siteTitle} />
      </Link>
    </div>
    <nav>
      <ul>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
